import React, { useEffect, useState } from "react"
import styled from "styled-components"

import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Logo from "../../../static/RealCedar_simple-color.svg"
import Button from "components/button"

import Spacer from "components/spacer"
import Textarea from "components/textarea"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog"

import * as theme from "theme"
import { useStore } from "store"
import VirtualSampleViewer from "../VirtualSampleViewer"

import { addToCart, handleMaximumItems, itemsInCart, itemsInSection } from "./"

const Order = ({ introduction, uri, sections, cart, setCart, setIsConfirmingOrder, ...rest }) => {
  const [, dispatch] = useStore()
  const [selectedSample, setSelectedSample] = useState(null)
  const [open, setOpen] = useState(false)

  // ✅ Updated functions to control modal state properly
  const openModal = (sample) => {
    setSelectedSample(sample)
    setOpen(true)
  }

  const closeModal = () => {
    setSelectedSample(null)
    setOpen(false)
  }

  useEffect(() => {
    // enforce maximum items on cart update
    handleMaximumItems(cart, setCart, sections)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  return (
    <div>
      <div className="flex flex-row flex-wrap lg:flex-nowrap sticky top-[100px] bg-[#FDFAFA] z-50 w-full py-[15px] md:pl-[15px]">
        {introduction && <Textarea content={introduction} className="max-w-[550px] md:px-[15px] " />}

        <div className="flex flex-col w-fit">
          <div classsName="flex flex-row w-fit relative">
            <CartTitle className="w-fit">Items In</CartTitle>

            <div className="w-fit flex flex-row">
              <CartTitle className="mr-[10px]">Cart</CartTitle>
              {!!cart && cart?.length ? (
                <CartItem>
                  <div className="text-white w-[15px] h-[15px] p-5 flex items-center rounded-full bg-[#669C34]">
                    <div className="right-[4px] relative text-white font-bold font-primary"> {cart?.length}</div>
                  </div>
                </CartItem>
              ) : (
                <div className="text-white w-[15px] h-[15px] p-5 flex items-center rounded-full bg-[#669C34]">
                  <div className="right-[5px] relative text-white font-bold font-primary">0</div>
                </div>
              )}
            </div>
          </div>
          {!!cart?.length && (
            <Spacer pb={20} pt={10} pr={20}>
              <StyledButton fullWidth onClick={() => setIsConfirmingOrder(true)}>
                Checkout
              </StyledButton>
            </Spacer>
          )}
        </div>
      </div>
      <Inner {...rest} className=" px-[13px] md:px-0 md:pl-[30px]">
        <Sections>
          {!!sections &&
            sections.map(({ headline, items, max, maxPerItem }, sectionIndex) => (
              <Section key={sectionIndex}>
                <Typography
                  component="h2"
                  variant="h3"
                  gutterBottom
                  id={headline && headline.split(" ").join("-").toLowerCase()}
                >
                  {headline}
                </Typography>

                {!!max && (
                  <Typography variant="h4" gutterBottom>
                    Choose up to {max} {headline}.
                  </Typography>
                )}

                <Items>
                  {!!items &&
                    items.map((item, itemIndex) => (
                      <Item key={itemIndex}>
                        <div>
                          <ImageContainer
                            onClick={() =>
                              dispatch({
                                type: "SET_LIGHTBOX",
                                payload: {
                                  open: true,
                                  slide: 0,
                                  slides: [
                                    {
                                      link: null,
                                      image: item.image.sourceUrl
                                    }
                                  ],
                                  options: {
                                    thumbs: false
                                  }
                                }
                              })
                            }
                          >
                            {item?.image?.sourceUrl && <img src={item.image.sourceUrl} alt={item.name} />}
                          </ImageContainer>

                          <ItemTitle component="h3" variant="h6" gutterBottom>
                            {item.name}
                          </ItemTitle>
                        </div>
                        <div className="flex flex-col sm:flex-row  flex-wrap sm:flex-nowrap w-full">
                          <CartButton
                            className="mb-[15px] sm:mb-0 whitespace-nowrap"
                            disabled={
                              (!!maxPerItem && itemsInCart(item.name, cart) >= maxPerItem) ||
                              (!!max && itemsInSection(cart, sectionIndex) >= max)
                            }
                            onClick={() => addToCart(item.name, max, maxPerItem, sectionIndex, cart, setCart)}
                          >
                            Add to Cart
                          </CartButton>

                          {item?.virtualView && (
                            <CartButton className="ml-0 sm:ml-[28px] whitespace-nowrap" onClick={() => openModal(item)}>
                              View Virtual
                            </CartButton>
                          )}
                        </div>
                      </Item>
                    ))}

                  <Item style={{ height: 0 }} />
                  <Item style={{ height: 0 }} />
                </Items>
              </Section>
            ))}
        </Sections>
      </Inner>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="max-w-4xl">
          <Logo className="w-[55px] h-fit absolute left-[15px] top-[15px]" />
          <DialogHeader>
            <div className="flex items-center justify-center relative">
              <DialogTitle>{selectedSample?.title}</DialogTitle>
            </div>
          </DialogHeader>
          {selectedSample && (
            <VirtualSampleViewer
              sample={selectedSample.virtualView}
              selectedSample={selectedSample}
              openModal={openModal}
              closeModal={closeModal}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

const CartTitle = styled(Typography)`
  display: block;
  font-size: 24px;
  font-weight: 400;
  text-decoration: none;
  color: ${theme.colors.primary};
  text-transform: uppercase;
`

const Inner = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
`

const Item = styled.div`
  width: 100%;
  margin-bottom: 30px;
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: 500px) {
    width: calc(50% - 10px);
  }
`
const ImageContainer = styled.div`
  width: 100%;
  height: 200px;
  margin-bottom: 20px;

  cursor: pointer;
  position: relative;

  img {
    position: relative;
    max-height: 100%;
    max-width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
  }
`

const CartButton = styled(Button)`
  && {
    padding: 10px 20px;
  }
`

const Items = styled.div`
  @media screen and (min-width: 800px) {
    flex-direction: row;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
const ItemTitle = styled(Typography)`
  display: flex;
  align-items: flex-start;
`

const Sections = styled.div``
const Section = styled.section`
  margin-bottom: 40px;
`
const CartItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledButton = styled(Button)`
  border: 1px solid ${theme.colors.primary};
`

export default Order
